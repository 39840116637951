<script setup lang="ts">
import { ref, nextTick, computed, onMounted } from "vue";
import Multiselect from "@vueform/multiselect";
import { defaultFormEmits, defaultInputProps } from "@/Utils/form";
import AppFormField from "@/Components/Shared/forms/AppFormField.vue";
import AppFormFieldLabel from "@/Components/Shared/forms/AppFormFieldLabel.vue";
import {multiselectClasses} from "@/Utils/Helpers";
import {twMerge} from "tailwind-merge";

export type SelectSize = "small" | "regular";

type Props = {
    allowEmpty?: boolean;
    tagPlaceholder?: string;
    inputLabel?: string;
    trackBy?: string;
    limit?: number;
    selected?: any;
    remote?: boolean;
    multiple?: boolean;
    hideSelected?: boolean;
    closeOnSelect?: boolean;
    preserveSearch?: boolean;
    clearOnSelect?: boolean;
    options?: any[];
    initialOptions?: any[];
    taggable?: boolean;
    isLoading?: boolean;
    size?: SelectSize;
} & defaultInputProps;

const {
    allowEmpty = false,
    tagPlaceholder = undefined,
    inputLabel = undefined,
    trackBy = "id",
    limit = 999,
    selected = null,
    remote = true,
    multiple = false,
    hideSelected = false,
    closeOnSelect = true,
    preserveSearch = false,
    clearOnSelect = false,
    options = null,
    initialOptions = null,
    taggable = false,
    isLoading = false,
    size = "regular"
} = defineProps<Props>();

const emit = defineEmits([...defaultFormEmits, "search", "change"]);

const selectedItems: any = ref(null);
const internalOptions: any = ref([]);
const select = ref();

// lifecycle
onMounted(() => {
    nextTick(() => {
        if (
            options &&
            options.length === 0 &&
            initialOptions &&
            initialOptions.length
        ) {
            internalOptions.value = initialOptions;
        }

        if (selected) {
            selectedItems.value = selected;
            emit("update:modelValue", selectedItems.value);
        }
    });
});

// computed
const availableOptions = computed(() => {
    if (options && options.length) {
        return options;
    } else if (internalOptions.value && internalOptions.value.length) {
        return internalOptions.value;
    }

    return null;
});

// methods
const onSearch = (searchQuery: any) => {
    emit("search", searchQuery);
};
const onUpdateValue = value => {
    emit("update:modelValue", value);
    emit("change", value);
};
defineExpose({ select });

const classes = computed(() => {
    const classes = { ...multiselectClasses };
    if (size === "small") {
        classes.container = twMerge(classes.container, "h-[32px]");
    }
    return classes;
});
</script>

<template>
    <AppFormField>
        <AppFormFieldLabel
            v-if="label"
            :for="name"
        >
            {{ label }}
        </AppFormFieldLabel>

        <Multiselect
            ref="select"
            v-model="selectedItems"
            :options="availableOptions"
            :searchable="true"
            :object="true"
            :mode="multiple ? 'tags' : 'single'"
            :track-by="trackBy"
            :value-prop="trackBy"
            :label="inputLabel"
            :placeholder="placeholder"
            :loading="loading"
            :max="limit"
            no-options-text="Geen opties gevonden"
            no-results-text="Geen resultaten gevonden"
            :resolve-on-load="false"
            :filter-results="false"
            :classes="classes"
            @change="onUpdateValue"
            @search-change="onSearch"
        />
    </AppFormField>
</template>
